import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/src/types/types-external';
import getIsThreadRead
    from '../../components/tapp/tapp-content/sites-tapp/messages/intercom-item/utils/getIsThreadRead';
import {
    sortMembers,
    sortMessages,
} from '../../components/tapp/tapp-content/sites-tapp/messages/intercom-item/utils/sort';
import { INTERCOM } from '../../constants/types';
import { Thread } from '../../types/intercom/thread';
import { mergeArrays } from '../../utils/mergeThreads';
import { resetState } from '../app/actions';
import {
    filterThreads,
    handleLoadIntercom,
    Intercom,
    setIntercomThreads,
    setUpdatedIntercomThread,
} from './actions';
import { SelectedItem } from '../../types/selectedItem';
import { threadSearchFilter } from '../../components/tapp/tapp-content/sites-tapp/messages/intercom-item/utils/thread';

const initialState: Intercom = {
    fetchedData: false,
    hasError: false,
    lastFetch: null,
    totalThreads: null,
    searchThreads: [],
    shownThreads: [],
    threads: [],
    isSearching: false,
    accessLocations: null,
};

const getNewThreadList = (current: string[], result: Thread[], threads: Thread[]): string[] => [...current.filter((t) => threads.some((t2) => t2.id === t)), ...threads.filter((t) => result.some((r) => r.id === t.id && !current.includes(t.id))).map((t) => t.id)];

const setShownThreads = (draft: WritableDraft<Intercom>, shownThreads: string[]) => {
    draft.shownThreads = shownThreads.sort((a, b) => {
        const threadA = draft.totalThreads[a];
        const threadB = draft.totalThreads[b];
        return new Date(threadB.lastMessageTime || threadB.creationTime).getTime() - new Date(threadA.lastMessageTime || threadA.creationTime).getTime();
    });
};

const setIntercom = (draft: WritableDraft<Intercom>, action: PayloadAction<{
    result: Thread[],
    selectedItem: SelectedItem,
    isSearch?: boolean
}>) => {
    const {
        result,
        selectedItem,
        isSearch = false,
    } = action.payload;

    if (!result) return;

    draft.fetchedData = true;
    let threads = mergeArrays(draft.totalThreads ? Object.values(draft.totalThreads) : [], result) as Thread[];

    const updatedThreadIds = result.map((updatedThread: Thread) => updatedThread.id);

    threads.forEach((thread: Thread, i: number) => {
        if (updatedThreadIds?.includes(thread.id)) {
            threads[i].members = sortMembers(thread.members);
            threads[i].messages = thread.messages ? sortMessages(thread.messages) : undefined;
        }
    });

    // Remove read threads
    threads = threads.map((thread: Thread) => {
        const isThreadRead = getIsThreadRead(thread);
        const isThreadSelected = selectedItem?.type === INTERCOM && selectedItem.id === thread.id;
        if (isThreadRead && !isThreadSelected) {
            return null;
        }

        return thread;
    }).filter((t: Thread) => t);

    draft.totalThreads = threads.reduce((acc: Record<string, Thread>, thread: Thread) => ({
        ...acc,
        [thread.id]: thread,
    }), {});

    if (isSearch) {
        draft.searchThreads = getNewThreadList(draft.searchThreads, result, threads);
    } else {
        draft.threads = getNewThreadList(draft.threads, result, threads);
    }

    setShownThreads(draft, getNewThreadList(draft.shownThreads, result, threads));
};

const reducer = createReducer(initialState, (builder) => {
    builder.addCase(handleLoadIntercom.fulfilled, (draft, action) => {
        if (action.payload.accessLocations) {
            draft.accessLocations = action.payload.accessLocations;
        }
        setIntercom(draft, action);
        if (action.payload.shouldUpdateLastFetch) {
            draft.lastFetch = new Date().toISOString();
        }
        draft.fetchedData = true;
        draft.hasError = false;
    });

    builder.addCase(handleLoadIntercom.rejected, (draft) => {
        draft.fetchedData = true;
        draft.hasError = true;
    });

    builder.addCase(setIntercomThreads, (draft, action) => {
        setIntercom(draft, action);
    });

    builder.addCase(filterThreads, (draft, { payload }) => {
        if (!payload?.trim()) {
            draft.shownThreads = draft.totalThreads ? Object.keys(draft.totalThreads) : [];
            draft.isSearching = false;
        } else {
            draft.shownThreads = draft.totalThreads ? Object.values(draft.totalThreads).filter(threadSearchFilter(payload)).map((t) => t.id) : [];
            draft.isSearching = true;
        }

        draft.searchThreads = [];
    });

    builder.addCase(setUpdatedIntercomThread.fulfilled, (draft, action) => {
        const {
            thread,
            selectedItem,
        } = action.payload;

        const isThreadRead = getIsThreadRead(thread);
        const isThreadSelected = selectedItem?.type === INTERCOM && selectedItem.id === thread.id;

        if (isThreadRead && !isThreadSelected) {
            draft.shownThreads = draft.shownThreads.filter((threadId) => thread.id !== threadId);
        }

        draft.totalThreads[thread.id] = thread;
        setShownThreads(draft, draft.shownThreads);
    });

    builder.addCase(resetState, () => initialState);
});

export default reducer;
