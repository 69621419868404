import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Gender } from 'chayns-api';
import { Thread } from '../../types/intercom/thread';
import { RootState } from '../rootReducer';
import {
    FILTER_THREADS,
    SET_INTERCOM_THREADS,
} from './types';
import { SelectedItem } from '../../types/selectedItem';
import { getChatFunctions } from '../../utils/getFunctionModule';
import { ChatApiResponse, Location } from '../../types/intercom/chat';
import { INTERCOM } from '../../constants/types';
import { setSelectedItem } from '../app/actions';
import {selectIntercomLastFetched} from "./selector";

export type Intercom = {
    fetchedData: boolean,
    hasError: boolean,
    lastFetch: string,
    totalThreads: Record<string, Thread>,
    searchThreads: string[],
    shownThreads: string[],
    threads: string[],
    isSearching: boolean,
    accessLocations: Location[] | null,
};

export const handleLoadIntercom = createAsyncThunk(
    'intercom/handleLoadIntercom',
    async ({ token, skip = 0, take = 25, searchString, onlyUnread = true }: { token: string, skip?: number, take?: number, searchString?: string, onlyUnread?: boolean }, thunkApi) => {
        const {
            loadThreads,
            searchThreads,
            loadAccessLocations,
        } = await getChatFunctions();
        const state = thunkApi.getState() as RootState;

        let accessLocations: Location[];

        if (!state.intercom.accessLocations) {
            const response = await loadAccessLocations({
                accessToken: token,
            });

            if (response.status === 200 || response.status === 204) {
                accessLocations = response.data;
            }
        }

        const isSearch = !!searchString?.trim();

        const threadOptions = onlyUnread ? {
            skip,
            take,
            shouldLoadOnlyUnread: true,
            shouldIncludeLocations: true,
        } : {
            shouldIncludeLocations: true,
            lastLoadTime: selectIntercomLastFetched(state),
        };

        const { user } = state;

        const parameters = {
            accessLocations: accessLocations || state.intercom.accessLocations || [],
            accessToken: token,
            user: {
                userId: user?.userId,
                uacGroups: [],
                personId: user?.personId,
                firstName: user?.firstname,
                lastName: user?.lastname,
                gender: Gender.Unknown,
            },
        };

        let threads: ChatApiResponse<Thread[]>;

        if (isSearch) {
            threads = await searchThreads({
                ...threadOptions,
                searchString,
            }, parameters);
        } else {
            threads = await loadThreads(threadOptions, parameters);
        }

        if (threads.status !== 200 && threads.status !== 204) {
            return thunkApi.rejectWithValue(threads);
        }

        const { selectedItem } = state.app;
        return {
            result: threads.data,
            selectedItem,
            isSearch,
            accessLocations,
            shouldUpdateLastFetch: !isSearch && skip === 0,
        };
    },
);

export const filterThreads = createAction<string | undefined>(FILTER_THREADS);

export const setIntercomThreads = createAction<{
    result: Array<Thread>,
    selectedItem: SelectedItem,
    updateLastFetch: boolean
}>(SET_INTERCOM_THREADS);

export const setUpdatedIntercomThread = createAsyncThunk('intercom/setUpdatedIntercomThread', async ({ thread }: { thread: Thread }, thunkApi) => {
    const {
        getThreadWithGroupLocationId,
    } = await getChatFunctions();
    const { user, intercom: { accessLocations, totalThreads }, app: { selectedItem } } = thunkApi.getState() as RootState;

    const updatedThread = selectedItem?.type === INTERCOM && selectedItem.id === thread.id ? {
        ...thread,
        groupLocationId: totalThreads[selectedItem.id]?.groupLocationId,
    } : getThreadWithGroupLocationId(thread, {
        accessLocations: accessLocations || [],
        user: {
            userId: user?.userId,
            uacGroups: [],
            personId: user?.personId,
            firstName: user?.firstname,
            lastName: user?.lastname,
            gender: Gender.Unknown,
        },
    });

    return {
        thread: updatedThread,
        selectedItem,
    };
});

export const handleLoadThreadById = createAsyncThunk(
    'intercom/handleLoadThreadById',
    async ({ id, token }: { id: string, token: string }, thunkApi) => {
        const { app: { selectedItem }, intercom: { totalThreads, accessLocations }, user } = thunkApi.getState() as RootState;

        const {
            loadThread,
        } = await getChatFunctions();

        const thread = await loadThread(id, {
            accessToken: token,
            accessLocations,
            user: {
                userId: user?.userId,
                uacGroups: [],
                personId: user?.personId,
                firstName: user?.firstname,
                lastName: user?.lastname,
                gender: Gender.Unknown,
            },
        });

        if (thread.status !== 200 && thread.status !== 204) {
            return thunkApi.rejectWithValue(thread);
        }

        if (selectedItem?.type === INTERCOM && selectedItem.id === thread.data.id) {
            const currentThread = totalThreads[selectedItem.id];
            const deletedMembers = thread.data.members.filter((member) => {
                if (!member.deletionTime) {
                    return false;
                }

                const currentMember = currentThread?.members.find((m) => m.id === member.id);
                if (!currentMember) {
                    return false;
                }
                return currentMember.deletionTime !== member.deletionTime;
            });
            if (deletedMembers.length > 0) {
                if (currentThread.groupLocationId && deletedMembers.some((member) => member.locationId === currentThread.groupLocationId)) {
                    thunkApi.dispatch(setSelectedItem(null));
                } else if (!currentThread.groupLocationId && deletedMembers.some((member) => member.tobitId === user?.userId)) {
                    thunkApi.dispatch(setSelectedItem(null));
                }
            }
        }

        thunkApi.dispatch(setIntercomThreads({
            result: [thread.data],
            selectedItem,
            updateLastFetch: false,
        }));
        return null;
    },
);
